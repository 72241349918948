import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindItAvaliacoesRespostasComparativo(id_avaliacao,id_usuario,id_avaliacao_pergunta) {
  console.log('id_avaliacao -' + id_avaliacao)
  console.log('id_usuario -' + id_usuario)
  console.log('id_avaliacao_pergunta -' + id_avaliacao_pergunta)
  return await api
    .post("avaliacoes/it/find/repostas/avaliacao ", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_avaliacao,
      id_usuario,id_avaliacao_pergunta
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
