import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function CreateCompetencia(desc_competencia_pergunta) {
  return await api
    .post("competencias/perguntas/create ", {
      id_cliente: localStorage.getItem("id_cliente"),
      desc_competencia_pergunta
    })
    .then((response) => {
      NotificaSuccess(desc_competencia_pergunta + ' criada com sucesso...');
      return response.data;
    })
    .catch((err) => {
      NotificaError("Usuário ou senha inválidos");
      return err;
    });
}
