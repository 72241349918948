import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindAvaliacoesPorStatusDoUsuarioEavaliacao(id_usuario, id_avaliacao,sn_preenchido) {
  return await api
    .post("avaliacoes/it/find/pendentes/usuario/alvo", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_usuario, id_avaliacao,sn_preenchido
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro!");
      return err;
    });
}
