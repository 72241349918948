import styled from "styled-components";

export const List = styled.ul`
  margin: 16px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  max-width: 100% !important;
  background-color: none;
  list-style: none !important;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  grid-gap: 8px !important;

  li {
    margin: 8px;
    background-color: var(--white);
    padding: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
    color: var(--text-body);

    p{
      padding-bottom: 8px;
      svg {
        margin-right: 8px;
      }
    }

    &:hover {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25) !important;
      cursor: pointer;
    }
  }
`;
