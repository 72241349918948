import React, { Fragment } from "react";

interface Action {
  obs: string;
  place: sting;
  valor: sting;
  handleSetInfo: () => void;
}

export function InputDefault({ obs, place,valor, handleSetInfo }: Action) {
  return (
    <Fragment>
      <small>{obs}</small>
      <input
        placeholder={place}
        type="text"
        value={valor}
        onChange={(e) => handleSetInfo(e.target.value)}
      />
    </Fragment>
  );
}
