import "./styles.css";

import { Button } from "./styles";
import React from "react";

interface Action {
  titleLink: string;
  FunctionClick: () => void;
  tipoBotao: string;
}

export const LinkDefault = ({
  titleLink,
  FunctionClick,
  tipoBotao
}: Action) => (
  <Button onClick={FunctionClick} className={tipoBotao} id="buttonDefault">
    {titleLink}
  </Button>
);
