import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text-body);
  justify-content: center;
  align-items: center;

  select {
    margin-bottom: 1rem;
  }

  form {
    width: 50%;
  }
`;

export const Table = styled.table`
  margin-top: 1rem;
  text-align: left;
  width: 100%;
  thead {
    border-radius: 4px;
    padding: 8px;
    text-align: left;
    background-color: var(--primary-color0) !important;
    color: var(--white);

    th {
      border-radius: 4px;
      padding: 8px;
    }
  }

  tr {
    cursor: pointer;
  }
  tr td {
    padding: 8px;
  }
`;
