import { BrowserRouter, Route, Switch } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Usuarios/Login";
import Logout from "./pages/Usuarios/Logout";
import React from "react";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/logout" exact component={Logout} />
        <Route path="*" exact component={Login} />
      </Switch>
    </BrowserRouter>
  );
}
