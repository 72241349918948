import 'moment/locale/pt-br';

import React, {useState} from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "./styles";
import { CreateItAvaliacoes } from '../../../Functions/Avaliacoes/CreateItAvaliacoes';
import { FiAlertCircle } from 'react-icons/fi';
import { LinkDefault } from '../../Others/LinkDefault';
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_avaliacao: String;
  desc_avaliacao: String;
  id_avaliacao: String;
}

Modal.setAppElement("#root");

export function LiberarAvaliacao({
  isOpen,
  handleChangeModalIsOpen,
  desc_avaliacao,
  usuario_criacao,
  id_avaliacao
}: Action) {

  const usuario_logado = localStorage.getItem("usuario");
  const [isVisibleButtonConfirmar,setisVisibleButtonConfirmar] = useState(false);

  const handleLiberarAvaliacao = () => {
    CreateItAvaliacoes(id_avaliacao).then(() => {
      closeModal();
    })
  }

  const closeModal = () => {
    setisVisibleButtonConfirmar(false)
    handleChangeModalIsOpen();
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={closeModal} />
      <Container>
        <h2>{desc_avaliacao}</h2>
        <h2>{usuario_criacao}</h2>

        <p>Você está prestes a liberar a avaliação para todos os colaboradores.</p>
        <p>Tenha certeza que conferiu os seguintes itens.</p>
        <p>•	Gestores por centro de custo.</p>
        <p>•	Colaboradores Cadastrados.</p>
        <p>•	Perguntas das avaliação.</p>
        <br/>
        <br/>

        {usuario_criacao ?
          usuario_criacao.toUpperCase() !== usuario_logado.toUpperCase() ?
          <LinkDefault
            titleLink="Estou ciente que essa ação é irreversível"
            tipoBotao="link-primary"
            FunctionClick={() => setisVisibleButtonConfirmar(true)}
          />
        :
          <p>
            <FiAlertCircle color="red" size="32"/> Outra pessoa deve liberar essa avaliação.
          </p> : ''
        }

          {isVisibleButtonConfirmar ?
            <LinkDefault
              titleLink="Clique aqui para liberar Avaliação"
              tipoBotao="link-primary"
              FunctionClick={() => handleLiberarAvaliacao(true)}
            />
          : ''}

      </Container>

    </Modal>
  );
}
