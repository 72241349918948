import api from "../../services/api";

export async function FindUsuariosByID(id_usuario) {
  const id_cliente = localStorage.getItem("id_cliente");
  return await api
    .post("usuarios/find/id", { id_cliente, id_usuario })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      localStorage.clear();
      return err;
    });
}
