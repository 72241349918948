import React, { useState } from "react";

import { Container } from "./styles";
import { FiLogIn } from "react-icons/fi";
import { FindAutenticar } from "../../Functions/Usuarios/FindAutenticar";
import Loader from "../../Components/Loader/Login";
import LogoHP from "../../assets/logohp.png";

export default function Login() {
  const [ usuario, setusuario] = useState("");
  const [ senha, setSenha] = useState("");
  const [ Loading, setLoading] = useState(false);

  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    FindAutenticar(usuario, senha);
  };

  return (
    <Container>
      <img src={LogoHP} width="90px" height="90px" alt="Logo Hospital Paulista"/>
      <form onSubmit={handleLogin}>
        <input placeholder="Usuário" type="text" onChange={(e) => setusuario(e.target.value)} />
        <input placeholder="Senha" type="password" onChange={(e) => setSenha(e.target.value)} />
        <button id="button-default" className="button-primary">
          Entrar <FiLogIn size="16" color="f0f2f5"/>
        </button>
        <Loader display={Loading} />
      </form>
    </Container>
  );
}
