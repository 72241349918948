import 'moment/locale/pt-br';

import * as moment from 'moment';

import React, { useEffect, useState } from "react";

import {AvaliacaoCreate} from "../Create";
import {AvaliacaoUpdate} from "../Update";
import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { FindAvaliacoes } from "../../../Functions/Avaliacoes/FindAvaliacoes";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import Loader from "../../Loader";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function AvaliacoesMainLista({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [avaliacoes, setavaliacoes] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [id, setid] = useState(false);
  const [isOpenUsuarioEdit, setisOpenUsuarioEdit] = useState(false);
  const [isOpenUsuarioCreate, setisOpenUsuarioCreate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindAvaliacoes().then((res) => {
        setavaliacoes(res);
        setLoading(false);
      });
    } else {
      setavaliacoes([]);
    }
  }, [isOpen, id_cliente, isOpenUsuarioCreate,isOpenUsuarioEdit]);

  const handleOpenEditUsuario= (data) => {
    setid(data);
    setisOpenUsuarioEdit(true);
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <HeaderLinksModal>
        <LinkDefault
          titleLink="Criar Avaliação"
          tipoBotao="link-primary"
          FunctionClick={() => setisOpenUsuarioCreate(true)}
        />
      </HeaderLinksModal>
      <Container>
        <select onChange={(e) => handleOpenEditUsuario(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Avaliações Criadas.
          </option>
          {avaliacoes.map((avaliacao) => (
            <option value={avaliacao.id}>{avaliacao.desc_avaliacao} - {moment(avaliacao.dt_competencia).locale("pt-br").format("dddd, DD MMMM  YYYY")}.</option>
          ))}
        </select>
      </Container>
      <AvaliacaoCreate
        isOpen={isOpenUsuarioCreate}
        handleChangeModalIsOpen={() => setisOpenUsuarioCreate(false)}
      />
      <AvaliacaoUpdate
        isOpen={isOpenUsuarioEdit}
        handleChangeModalIsOpen={() => setisOpenUsuarioEdit(false)}
        id={id}
      />
    </Modal>
  );
}
