import "./global.css";
import "./styles/buttons.css";
import "react-toastify/dist/ReactToastify.min.css";

import React, { Fragment } from "react";

import Routes from "./routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
      <Fragment>
        <ToastContainer />
        <Routes />
      </Fragment>
  );
}

export default App;
