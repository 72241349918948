import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindUsuarios(sn_ativo) {
  const id_cliente = localStorage.getItem("id_cliente");
  return await api
    .post("usuarios/find", { id_cliente, sn_ativo })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro usuarios");
      return err;
    });
}
