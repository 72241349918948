import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function CreatePerguntasCompetencia(id_competencia,desc_pergunta) {
  return await api
    .post("perguntas/create", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_competencia,
      desc_pergunta
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("erro perguntas/create");
      return err;
    });
}
