import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100% !important;
  background-color: var(--primary-color0);
  padding: 16px;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  svg {
    margin: 16px;
  }

  div p {
    color: var(--white);
    font-size: 1rem;
  }

  @media (max-width: 800px) {
    div p {
      display: none;
    }
  }
}
`;
export const Content = styled.header`
  background-color: var(--blue-primary-color) !important;
  width: 100%;
  display: flex;
  flex-direction: column;

  p {
    color: var(--white);
    margin-left: 32px;
    padding: 8px;
  }

  @media (max-width: 800px) {
    & {
      display: none;
    }
    &:button {
      font-size: 0.5rem;
      height: 0.5rem;
      margin: 20px;
    }
  }

  button {
    text-align: left;
    font-size: 0.8rem;
    height: 3rem;
    width: 80%;
    background-color: var(--blue-primary-color);
    color: var(--white);
    transition: filter 0.9s;
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }

  button:hover {
    background-color: var(--blue-primary-color-1);
    color: #fff;
    font-size: 1rem;
    filter: brightness(0.9);
  }
`;
export const SubTitle = styled.p`
  margin-top: 16px;
`;
export const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  place-content: center;
  align-items: center;
  text-align: center;
  padding-left: 64px;
  padding-right: 64px;
  background-color: var(--blue-light);
  h1 {
    font-size: 32px;
  }
`;
export const Links = styled.ul`
  margin: 32px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr) !important;
  grid-gap: 16px !important;
  justify-content: center;
  place-content: center;
  text-align: center;
  text-decoration: none;

  a {
    color: var(--text-body);
    padding: 16px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
      margin: 8px;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
`;
export const Link = styled.li`
  padding: 16px;
  a {
    text-decoration: none;
  }
  p {
    color: var(--gray);
  }

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
