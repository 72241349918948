import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function DeleteAvaliacaoPergunta(id) {
  return await api
    .post("avaliacoes/perguntas/delete ", {
      id_cliente: localStorage.getItem("id_cliente"),
      id,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro ao inserir registro");
      return err;
    });
}
