import { FiEdit, FiFolder, FiLogOut, FiStar, FiSunrise, FiUsers } from "react-icons/fi";
import React, { useEffect, useState } from "react";

import { AvaliacoesMainLista } from "../AvaliacoesMain/Lista";
import CentroCustoLista from "../CentrosCusto/Lista";
import { CompetenciasLista } from "../Competencias/Lista";
import { Container } from "./styles";
import { RespostasLista } from "../Respostas/Lista";
import {UsuariosLista} from "../Usuarios/Lista";

interface Action {
  nm_usuario: string;
}

function Header({ nm_usuario }: Action) {
  const id_cliente = localStorage.getItem("id_cliente");
  const [isOpenUsuarios, setisOpenUsuarios] = useState(false);
  const [isOpenCentroCusto, setisOpenCentroCusto] = useState(false);
  const [isOpenCompetencia, setisOpenCompetencia] = useState(false);
  const [IsOpenAvaliacoesMain, setIsOpenAvaliacoesMain] = useState(false);
  const [isOpenRespostasCreate, setisOpenRespostasCreate] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      window.location.href = "/logout";
    }
  }, [isOpenCentroCusto,isOpenUsuarios]);

  const logout = () => {
    localStorage.clear();
    setisOpenCentroCusto(!isOpenCentroCusto);
  }

  return (
    <Container>
      <div>
        <p>{nm_usuario}</p>
      </div>
      <div>
        <FiFolder
          color="white"
          size="24"
          title="Centros de Custo"
          onClick={() => setisOpenCentroCusto(true)}
        />
        <FiStar
          color="white"
          size="24"
          title="Competências"
          onClick={() => setisOpenCompetencia(true)}
        />
        <FiUsers
          color="white"
          size="24"
          title="Usuários"
          onClick={() => setisOpenUsuarios(true)}
        />

        <FiEdit
          color="white"
          size="24"
          title="Respostas"
          onClick={() => setisOpenRespostasCreate(true)}
        />

        <FiSunrise
          color="white"
          size="24"
          title="Avaliações"
          onClick={() => setIsOpenAvaliacoesMain(true)}
        />

        <FiLogOut
          color="red"
          size="24"
          title="Sair"
          onClick={() => logout("logout")}
        />
      </div>

      <UsuariosLista
        isOpen={isOpenUsuarios}
        handleChangeModalIsOpen={() => setisOpenUsuarios(!isOpenUsuarios)}
        id_cliente={id_cliente}
      />

      <CentroCustoLista
        isOpen={isOpenCentroCusto}
        handleChangeModalIsOpen={() => setisOpenCentroCusto(!isOpenCentroCusto)}
        id_cliente={id_cliente}
      />

      <CompetenciasLista
        isOpen={isOpenCompetencia}
        handleChangeModalIsOpen={() => setisOpenCompetencia(!isOpenCompetencia)}
      />

      <AvaliacoesMainLista
        isOpen={IsOpenAvaliacoesMain}
        handleChangeModalIsOpen={() => setIsOpenAvaliacoesMain(!IsOpenAvaliacoesMain)}
      />

      <RespostasLista
        isOpen={isOpenRespostasCreate}
        handleChangeModalIsOpen={() => setisOpenRespostasCreate(!isOpenRespostasCreate)}
      />
    </Container>
  );
}

export default React.memo(Header);
