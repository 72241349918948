import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function UpdateItAvaliacao(id, id_avaliacao, sn_preenchido) {
  return await api
    .post("avaliacoes/it/update/status", {
      id_cliente: localStorage.getItem("id_cliente"),
      id,
      id_avaliacao,
      sn_preenchido,
    })
    .then((response) => {
      NotificaSuccess("Status atualizado com sucesso...");
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro! a avaliação está fechada...");
      return err;
    });
}
