import { Container, Table } from "./styles";

import { FiTrash2 } from "react-icons/fi";
import React from "react";

interface Action {
  infos: Array;
  handleDeleteItem: () => void;
}

export const AvaliacaoMainPerguntasInseridas = ({infos,handleDeleteItem}: Action) => (
  <Container>
    <h3>Quantidade de perguntas inseridas: {infos.length}</h3>
    <Table>
      <thead>
        <th>Competência</th>
        <th>Pergunta</th>
        <th></th>
      </thead>
      {infos.map((info, index) => (
        <tr key={index}>
          <td>{info.desc_competencia_pergunta}</td>
          <td>{info.desc_pergunta}</td>
          <td><FiTrash2 color="red" onClick={() => handleDeleteItem(info.id)}/></td>
        </tr>
      ))}
    </Table>
  </Container>
);
