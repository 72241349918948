import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateCentroCusto } from "../../../Functions/CentroCusto/CreateCentroCusto";
import { FindCentroCustoPorSetor } from "../../../Functions/CentroCusto/FindCentroCustoPorSetor";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  setores: Array;
}

Modal.setAppElement("#root");

function CentroCustoCreate({
  isOpen,
  handleChangeModalIsOpen,
  setores,
}: Action) {
  const [id_setor, setid_setor] = useState("");
  const [nm_centro_custo, setnm_centro_custo] = useState("");
  const [centro_custo_existentes, setcentro_custo_existentes] = useState([]);

  async function handleCreateCentroCusto(e) {
    e.preventDefault();
    CreateCentroCusto(id_setor, nm_centro_custo).then(() => {
      handleChangeModalIsOpen();
    });
  }

  useEffect(() => {
    if (id_setor) {
      FindCentroCustoPorSetor(id_setor).then((res) => {
        setcentro_custo_existentes(res);
      });
    }
  }, [id_setor, isOpen]);

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreateCentroCusto} encType="multipart/form-data">
          <select onChange={(e) => setid_setor(e.target.value)}>
            <option value="DEFAULT" selected disabled>
              Selecione um setor
            </option>
            {setores.map((setor) => (
              <option value={setor.id}> {setor.nm_setor}</option>
            ))}
          </select>
          <p>
            <b>Centros de custos criados: </b>
          </p>
          {centro_custo_existentes.map((setor) => (
            <p>{setor.nm_centro_custo}</p>
          ))}
          <input onChange={(e) => setnm_centro_custo(e.target.value)} />
          <button type="submit"> Criar centro de custo</button>
        </form>
      </Container>
    </Modal>
  );
}

export default React.memo(CentroCustoCreate);
