import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindAvaliacoesPorAvaliador() {
  return await api
    .post("avaliacoes/it/find/usuario/avaliador", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_usuario: localStorage.getItem("id_usuario"),
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Usuário ou senha inválidos");
      return err;
    });
}
