import styled from "styled-components";

export const msgErro = styled.p`
  color: red !important;
  size: 1rem;
`
export const Container = styled.div`

  color: var(--text-body);

  form {
    width: 50%;
  }

  p {
    margin-top: 1rem;
  }
`;
