import { Container, Form } from "./styles";
import {
  FiArchive,
  FiEye,
  FiFolder,
  FiSunrise,
  FiToggleLeft,
  FiToggleRight,
  FiUser,
} from "react-icons/fi";
import React, { useEffect, useState } from "react";

import { AvaliacoesPendentes } from "./AvaliacoesPendentes";
import { CloseModal } from "../../Others/CloseModal";
import { CreateItAvaliacoesPerguntas } from "../../../Functions/Avaliacoes/CreateItAvaliacoesPerguntas";
import { FindAvaliacoesPerguntas } from "../../../Functions/Avaliacoes/FindAvaliacoesPerguntas";
import { FindAvaliacoesPorID } from "../../../Functions/Avaliacoes/FindAvaliacoesPorID";
import { FindAvaliacoesPorStatusDoUsuarioEavaliacao } from "../../../Functions/Avaliacoes/FindAvaliacoesPorStatusDoUsuarioEavaliacao";
import { FindItAvaliacoesPerguntas } from "../../../Functions/Avaliacoes/FindItAvaliacoesPerguntas";
import { FindItAvaliacoesRespostasComparativo } from "../../../Functions/Avaliacoes/FindItAvaliacoesRespostasComparativo";
import { FindRespostas } from "../../../Functions/Respostas/FindRespostas";
import { LinkDefault } from "../../Others/LinkDefault";
import Modal from "react-modal";
import { ModalFull } from "../../../styles/modal.css";
import { UpdateItAvaliacao } from "../../../Functions/Avaliacoes/UpdateItAvaliacao";
import { UpdateValorResposta } from "../../../Functions/Respostas/UpdateValorResposta";
import { ViewRespostasAnteriores } from "./RespostasAnteriores";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  avaliacaoSelecionada: Array;
}

Modal.setAppElement("#root");

export function AvaliacaoSelecionada({
  isOpen,
  handleChangeModalIsOpen,
  avaliacaoSelecionada,
}: Action) {
  const [perguntasInseridas, setperguntasInseridas] = useState([]);
  const [respostas, setrespostas] = useState([]);
  const [avaliacaoMain, setavaliacaoMain] = useState([]);
  const [perguntaSelecionadaView, setperguntaSelecionadaView] = useState([]);
  const [AvaliacoesPendentesColaborador, setAvaliacoesPendentesColaborador] = useState([]);
  const [reload, setreload] = useState(false);
  const [isOpenRespostasAnteriores, setisOpenRespostasAnteriores] = useState(false);
  const [isOpenAvaliacoesPendentes, setisOpenAvaliacoesPendentes] = useState(false);
  const [desc_pergunta, setdesc_pergunta] = useState("");

  useEffect(() => {
    FindRespostas("S").then((res) => {
      setrespostas(res);
    });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (avaliacaoSelecionada.desc_avaliacao_tipo === "COLETIVO") {
        FindAvaliacoesPorStatusDoUsuarioEavaliacao(
          avaliacaoSelecionada.id_usuario_alvo,
          avaliacaoSelecionada.id_avaliacao,
          "N"
        ).then((res) => {
          if (res.length > 0) {
            setisOpenAvaliacoesPendentes(true);
            setAvaliacoesPendentesColaborador(res);
          }
        });
      }
    }
    setisOpenAvaliacoesPendentes(false);
  }, [isOpen, avaliacaoSelecionada, handleChangeModalIsOpen]);

  useEffect(() => {
    if (isOpen)
      FindItAvaliacoesPerguntas(avaliacaoSelecionada.id).then((res) => {
        setperguntasInseridas(res);
        if (res.length === 0) {
          FindAvaliacoesPerguntas(avaliacaoSelecionada.id_avaliacao).then(
            (res) => {
              let i = 0;
              while (i < res.length) {
                CreateItAvaliacoesPerguntas(
                  avaliacaoSelecionada.id,
                  res[i].id
                ).then((res) => {});
                i++;
              }
            }
          );
          setreload(!reload);
        }
      });

    if (isOpen) {
      FindAvaliacoesPorID(avaliacaoSelecionada.id_avaliacao).then((res) => {
        setavaliacaoMain(res[0]);
      });
    }
  }, [isOpen, avaliacaoSelecionada, reload]);

  const handleAlterReposta = (vl_resposta, id_pergunta, id_avaliacao) => {
    UpdateValorResposta(id_pergunta, vl_resposta, id_avaliacao).then(
      (res) => {}
    );
  };

  const handleFinalizarAvaliacao = (data) => {
    UpdateItAvaliacao(
      avaliacaoSelecionada.id,
      avaliacaoSelecionada.id_avaliacao,
      "S"
    ).then(() => {
      handleChangeModalIsOpen();
    });
  };

  const handleFindRespostasAnteriores = (data) => {
    setdesc_pergunta(data.desc_pergunta);
    setisOpenRespostasAnteriores(!isOpenRespostasAnteriores)
    FindItAvaliacoesRespostasComparativo(avaliacaoSelecionada.id_avaliacao,avaliacaoSelecionada.id_usuario_alvo,data.id_avaliacao_pergunta).then((res) => {
      setperguntaSelecionadaView(res);
    })
  };

  return (
    <Modal transparent isOpen={isOpen} style={ModalFull} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      {avaliacaoSelecionada ? (
        <div>
          <p>
            <FiSunrise /> <b> {avaliacaoSelecionada.desc_avaliacao}</b>
          </p>
          <p>
            <FiUser /> {avaliacaoSelecionada.nm_usuario_alvo}
          </p>
          <p>
            <FiArchive /> {avaliacaoSelecionada.desc_avaliacao_tipo}
          </p>
          <p>
            <FiFolder /> {avaliacaoSelecionada.nm_centro_custo}
          </p>
          {avaliacaoMain.sn_ativo === "S" ? (
            <FiToggleLeft size="32" title="Avaliação Aberta" color="green" />
          ) : (
            <FiToggleRight size="32" title="Avaliação Fechada" color="red" />
          )}
        </div>
      ) : (
        ``
      )}
      <Container>
        {perguntasInseridas.map((pergunta) => (
          <Form>
            <p>{pergunta.desc_pergunta} </p>
            {avaliacaoSelecionada.desc_avaliacao_tipo === 'COLETIVO' ?
              <FiEye size="24"
                title="Visualizar Respostas anteriores"
                onClick={() => handleFindRespostasAnteriores(pergunta)}
              />
            : '' }

            <select
              onChange={(e) =>
                handleAlterReposta(
                  e.target.value,
                  pergunta.id,
                  avaliacaoSelecionada.id_avaliacao
                )
              }
            >
              <option value="DEFAULT" selected disabled>
                Respostas Cadastradas.
              </option>
              {respostas.map((resposta) => (
                <option value={resposta.vl_resposta}>
                  {resposta.desc_resposta}
                </option>
              ))}
            </select>
          </Form>
        ))}
      </Container>
      <LinkDefault
        titleLink="Finalizar Avaliação"
        tipoBotao="link-primary"
        FunctionClick={() => handleFinalizarAvaliacao()}
      />

      <ViewRespostasAnteriores
        isOpen={isOpenRespostasAnteriores}
        handleChangeModalIsOpen={() => setisOpenRespostasAnteriores(!isOpenRespostasAnteriores)}
        infos={perguntaSelecionadaView}
        desc_pergunta={desc_pergunta}
      />

      <AvaliacoesPendentes
        isOpen={isOpenAvaliacoesPendentes}
        handleChangeModalIsOpen={handleChangeModalIsOpen}
        infos={AvaliacoesPendentesColaborador}
      />
    </Modal>
  );
}
