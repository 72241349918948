import { FiSunrise, FiUnlock, FiUser } from "react-icons/fi";
import React, { useEffect, useState } from "react";

import { AvaliacaoMainPerguntasInseridas } from "../AvaliacaoMainPerguntasInseridas";
import { AvaliacaoMainResumoPerguntas } from "../AvaliacaoMainResumoPerguntas";
import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreatePerguntaInAvaliacao } from "../../../Functions/Avaliacoes/CreatePerguntaInAvaliacao";
import { DeleteAvaliacaoPergunta } from "../../../Functions/Avaliacoes/DeleteAvaliacaoPergunta";
import { FindAvaliacoesPerguntas } from "../../../Functions/Avaliacoes/FindAvaliacoesPerguntas";
import { FindAvaliacoesPorID } from "../../../Functions/Avaliacoes/FindAvaliacoesPorID";
import { FindPerguntas } from "../../../Functions/Perguntas/FindPerguntas";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LiberarAvaliacao } from "../LiberarAvaliacao";
import Loader from "../../Loader";
import Modal from "react-modal";
import StatusAvaliacao from "../StatusAvaliacao";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
  id: String;
}

Modal.setAppElement("#root");

export function AvaliacaoUpdate({
  isOpen,
  handleChangeModalIsOpen,
  id,
}: Action) {
  const [Loading, setLoading] = useState(false);
  const [reload, setreload] = useState(false);
  const [info, setinfo] = useState([]);
  const [perguntasCadastradas, setperguntasCadastradas] = useState([]);
  const [perguntasDaAvaliacao, setperguntasDaAvaliacao] = useState([]);
  const [resumoGrupo, setresumoGrupo] = useState([]);
  const [isOpenLiberarAvaliacao, setisOpenLiberarAvaliacao] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindAvaliacoesPorID(id).then((res) => {
        setinfo(res[0]);
      });

      FindAvaliacoesPerguntas(id).then((res) => {
        setperguntasDaAvaliacao(res);
        let grupos = [];
        let i = 0;
        while (i < res.length) {
          grupos.push(res[i].desc_competencia_pergunta);
          i++;
        }
        const objectArray = Object.assign({}, ...Array.from(new Set(grupos), key => ({[key]: grupos.filter(value => value === key).length})));
        var result = Object.keys(objectArray).map((key) => [ key, objectArray[key]]);
        setresumoGrupo(result);
        setLoading(false);
      });
    }
  }, [isOpen, id, reload]);

  useEffect(() => {
    FindPerguntas().then((res) => {
      setperguntasCadastradas(res);
    });
  }, [id]);

  const handleSetPerguntaInAvaliacao = (data) => {
    CreatePerguntaInAvaliacao(id, data).then(() => {
      setreload(!reload);
    });
  };

  const handleDeleteItem = (data) => {
    DeleteAvaliacaoPergunta(data).then(() => {
      setreload(!reload);
    });
  };

  const handleLiberarAvaliacao = (data) => {
    setisOpenLiberarAvaliacao(!isOpenLiberarAvaliacao);
  };

  const handleReload = (data) => {
    setreload(!reload);
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <StatusAvaliacao
        sn_ativo={info.sn_ativo}
        handleReload={handleReload}
        id={info.id}
      />
      <HeaderLinksModal>
        <FiUnlock
          size="32"
          title="Liberar avaliação"
          onClick={() => handleLiberarAvaliacao(id)}
        />
      </HeaderLinksModal>
      <Container>
        <h2>
          <FiSunrise /> {info.desc_avaliacao}
        </h2>
        <p>
          <b>Criado por: </b> <FiUser />
          {info.usuario_criacao}
        </p>
        <p>
          <b>Autorizado por: </b> <FiUser />
          {info.usuario_autorizacao}
        </p>

        <select onChange={(e) => handleSetPerguntaInAvaliacao(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Lista de perguntas cadastradas no sistema.
          </option>
          {perguntasCadastradas.map((pergunta) => (
            <option value={pergunta.id}>{pergunta.desc_pergunta} </option>
          ))}
        </select>

        <AvaliacaoMainResumoPerguntas infos={resumoGrupo} />

        <AvaliacaoMainPerguntasInseridas
          infos={perguntasDaAvaliacao}
          handleDeleteItem={handleDeleteItem}
        />

        <LiberarAvaliacao
          isOpen={isOpenLiberarAvaliacao}
          handleChangeModalIsOpen={() => setisOpenLiberarAvaliacao(!isOpenLiberarAvaliacao)}
          desc_avaliacao={info.desc_avaliacao}
          usuario_criacao={info.usuario_criacao}
          id_avaliacao={info.id}
        />
      </Container>
    </Modal>
  );
}
