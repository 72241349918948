import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { FindRespostas } from "../../../Functions/Respostas/FindRespostas";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import Loader from "../../Loader";
import Modal from "react-modal";
import RespostasCreate from '../Create';
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function RespostasLista({
  isOpen,
  handleChangeModalIsOpen,
}: Action) {
  const [ListaRespostas, setListaRespostas] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [IsOpenCreateResposta, setIsOpenCreateResposta] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindRespostas('S').then((res) => {
        setListaRespostas(res);
        setLoading(false);
      })
    }
  }, [isOpen,IsOpenCreateResposta]);

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />

      <HeaderLinksModal>
        <LinkDefault
          titleLink="Adicionar Resposta"
          tipoBotao="link-primary"
          FunctionClick={() => setIsOpenCreateResposta(true)}
        />
      </HeaderLinksModal>
      <Container>
        <select >
          <option value="DEFAULT" selected disabled>
            Respostas Cadastradas.
          </option>
          {ListaRespostas.map((resposta) => (
            <option value={resposta.id}>{resposta.desc_resposta} - Valor da resposta: {resposta.vl_resposta}</option>
          ))}
        </select>
      </Container>

      <RespostasCreate
        isOpen={IsOpenCreateResposta}
        handleChangeModalIsOpen={() => setIsOpenCreateResposta(false)}
      />
    </Modal>
  );
}
