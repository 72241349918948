import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindRespostas(sn_ativo) {
  return await api
    .post("respostas/find ", {
      id_cliente: localStorage.getItem("id_cliente"),
      sn_ativo
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro!");
      return err;
    });
}
