import axios from "axios";
const token = localStorage.getItem("token") || "";
const api = axios.create({
  baseURL: "https://apiavaliacaohp.dragendamento.com",
  headers: {
    Authorization: token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;
