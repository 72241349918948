import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function CreatePerguntaInAvaliacao(id_avaliacao, id_pergunta) {
  return await api
    .post("avaliacoes/perguntas/create ", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_avaliacao,
      nm_usuario: localStorage.getItem("nm_usuario"),
      id_pergunta,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro ao inserir registro");
      return err;
    });
}
