import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { FindUsuarios } from "../../../Functions/Usuarios/FindUsuarios";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import Loader from "../../Loader";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";
import UsuariosCreate from "../Create";
import {UsuariosUpdate} from "../Update";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function UsuariosLista({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [ListaUsuarios, setListaUsuarios] = useState([]);
  const [ListaUsuariosInvativos, setListaUsuariosInvativos] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [id_usuario, setid_usuario] = useState(false);
  const [isOpenUsuarioEdit, setisOpenUsuarioEdit] = useState(false);
  const [isOpenUsuarioCreate, setisOpenUsuarioCreate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindUsuarios("S").then((res) => {
        setListaUsuarios(res);
        setLoading(false);
      });
      FindUsuarios("N").then((res) => {
        setListaUsuariosInvativos(res);
        setLoading(false);
      });
    } else {
      setListaUsuarios([]);
    }
  }, [isOpen, id_cliente, isOpenUsuarioCreate,isOpenUsuarioEdit]);

  const handleOpenEditUsuario= (data) => {
    setid_usuario(data);
    setisOpenUsuarioEdit(true);
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />

      <HeaderLinksModal>
        <LinkDefault
          titleLink="Adicionar Usuário"
          tipoBotao="link-secondary"
          FunctionClick={() => setisOpenUsuarioCreate(true)}
        />
      </HeaderLinksModal>
      <Container>
        <select onChange={(e) => handleOpenEditUsuario(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Usuários ativos.
          </option>
          {ListaUsuarios.map((usuario) => (
            <option value={usuario.id}>{usuario.nm_centro_custo} - {usuario.nm_usuario}</option>
          ))}
        </select>
        <select onChange={(e) => handleOpenEditUsuario(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Usuários Inativos.
          </option>
          {ListaUsuariosInvativos.map((usuario) => (
            <option value={usuario.id}>{usuario.nm_centro_custo} - {usuario.nm_usuario}</option>
          ))}
        </select>
      </Container>
      <UsuariosCreate
        isOpen={isOpenUsuarioCreate}
        handleChangeModalIsOpen={() => setisOpenUsuarioCreate(false)}
      />
      <UsuariosUpdate
        isOpen={isOpenUsuarioEdit}
        handleChangeModalIsOpen={() => setisOpenUsuarioEdit(false)}
        id_usuario={id_usuario}
      />
    </Modal>
  );
}
