import React, { useEffect, useState } from "react";

import CentroCustoCreate from "../Create";
import { CentroCustoGestor } from "../UpdateGestor";
import { CloseModal } from "../../Others/CloseModal";
import { FiTool } from "react-icons/fi";
import { FindCentroCustoPorSetor } from "../../../Functions/CentroCusto/FindCentroCustoPorSetor";
import { FindSetores } from "../../../Functions/Setores/FindSetores";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import Loader from "../../Loader";
import Modal from "react-modal";
import SetorCreate from "../../Setor/Create";
import { StyleModal } from "../../../styles/modal.css";
import { Table } from "../../styles";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

function CentroCustoLista({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [setores, setsetores] = useState([]);
  const [centros_custo, setcentros_custo] = useState([]);
  const [centro_custo_selecionado, setcentro_custo_selecionado] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [id_setor, setid_setor] = useState(false);
  const [isOpenCentroCustoCreate, setisOpenCentroCustoCreate] = useState(false);
  const [isOpenSetor, setisOpenSetor] = useState(false);
  const [isOpenCentroCustoGestor, setisOpenCentroCustoGestor] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      FindSetores().then((res) => {
        setsetores(res);
        setLoading(false);
      });
    }
  }, [isOpen, id_cliente, isOpenCentroCustoCreate, isOpenSetor]);

  useEffect(() => {
    if (id_setor) {
      FindCentroCustoPorSetor(id_setor).then((res) => {
        setcentros_custo(res);
      });
    }
  }, [id_setor, isOpen, isOpenCentroCustoCreate]);

  const handlesetCentroCusto = (data) => {
    setcentro_custo_selecionado(data);
    setisOpenCentroCustoGestor(!isOpenCentroCustoGestor);
  };

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />

      <HeaderLinksModal>
        <LinkDefault
          titleLink="Criar Setor"
          tipoBotao="link-secondary"
          FunctionClick={() => setisOpenSetor(true)}
        />
        <LinkDefault
          titleLink="Criar centro de custo"
          tipoBotao="link-secondary"
          FunctionClick={() => setisOpenCentroCustoCreate(true)}
        />
      </HeaderLinksModal>
      <select onChange={(e) => setid_setor(e.target.value)}>
        <option value="DEFAULT" selected disabled>
          Selecione um setor
        </option>
        {setores.map((setor) => (
          <option value={setor.id}> {setor.nm_setor}</option>
        ))}
      </select>

      <Table>
        <thead>
          <th>Centro Custo</th>
          <th>Ações</th>
        </thead>
        {centros_custo.map((info, index) => (
          <tr key={index}>
            <td>{info.nm_centro_custo}</td>
            <td>
              <FiTool
                size="24"
                color="black"
                onClick={() => handlesetCentroCusto(info)}
              />{" "}
            </td>
          </tr>
        ))}
      </Table>

      <CentroCustoCreate
        isOpen={isOpenCentroCustoCreate}
        handleChangeModalIsOpen={() => setisOpenCentroCustoCreate(false)}
        setores={setores}
      />
      <SetorCreate
        isOpen={isOpenSetor}
        handleChangeModalIsOpen={() => setisOpenSetor(false)}
        setores={setores}
      />
      <CentroCustoGestor
        isOpen={isOpenCentroCustoGestor}
        handleChangeModalIsOpen={() => setisOpenCentroCustoGestor(false)}
        info={centro_custo_selecionado}
      />
    </Modal>
  );
}

export default React.memo(CentroCustoLista);
