import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { DeletePergunta } from "../../../Functions/Perguntas/DeletePergunta";
import { FiTrash } from "react-icons/fi";
import { FindPerguntasCompetencia } from "../../../Functions/Perguntas/FindPerguntasCompetencia";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import ListStyle from "../../../styles/ListStyle";
import Loader from "../../Loader";
import Modal from "react-modal";
import { PerguntasCreate } from "../CreatePerguntas"
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_competencia: String;
}

Modal.setAppElement("#root");

export function UsuariosUpdate({
  isOpen,
  handleChangeModalIsOpen,
  id_competencia
}: Action) {

  const [Loading, setLoading] = useState(false);
  const [isOpenCreate, setisOpenCreate] = useState(false);
  const [reload, setreload] = useState(false);
  const [Perguntas, setPerguntas] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindPerguntasCompetencia(id_competencia).then((res) => {
        setPerguntas(res);
        setLoading(false);
      })
    }
  }, [isOpen, id_competencia,isOpenCreate, reload]);

  const handleDeletePergunta= (data) => {
    DeletePergunta(data.id).then(() => {
      setreload(!reload);
    })
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <HeaderLinksModal>
        <LinkDefault
          titleLink="Adicionar Pergunta"
          tipoBotao="link-primary"
          FunctionClick={() => setisOpenCreate(true)}
        />
      </HeaderLinksModal>
      <h3>Total de perguntas dessa competência: {Perguntas.length}</h3>
      <ListStyle>
        {Perguntas.map((pergunta) => (
          <li>
            <p>{pergunta.desc_pergunta}</p>
            <br />
            <FiTrash
              onClick={(e) => handleDeletePergunta(pergunta)}
              size="24"
              color="red"
            />
          </li>
        ))}
      </ListStyle>

      <PerguntasCreate
        isOpen={isOpenCreate}
        handleChangeModalIsOpen={() => setisOpenCreate(!isOpenCreate)}
        id_competencia={id_competencia}
      />
    </Modal>
  );
}
