import { FiToggleLeft, FiToggleRight } from "react-icons/fi";

import React from "react";
import { UpdateAvaliacoesStatus } from "../../../Functions/Avaliacoes/UpdateAvaliacoesStatus";

interface Action {
  sn_ativo: String;
  id: String;
  handleReload: () => void;
}

function StatusAvaliacao({ id,sn_ativo,handleReload }: Action) {
  const updateStatus = (data) => {
    UpdateAvaliacoesStatus(id, data).then(() => {
      handleReload();
    })
  }
  return (
    <div>
      {sn_ativo === 'S' ?
        <FiToggleLeft
          size="32"
          title="Avaliação Aberta"
          color="green"
          onClick={() => updateStatus('N')}
        />

      :
      <FiToggleRight
        size="32"
        title="Avaliação Fechada"
        color="red"
        onClick={() => updateStatus('S')}
      />
      }

    </div>
  );
}

export default React.memo(StatusAvaliacao);
