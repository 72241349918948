import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function CreateUsuario(
  nm_usuario,
  id_centro_custo,
  id_tipo_acesso,
  usuario,
  senha,
  email,
  phone,
  nr_cracha
) {
  return await api
    .post("usuarios/create", {
      nm_usuario,
      id_centro_custo,
      id_tipo_acesso,
      usuario,
      senha,
      email,
      phone,
      nr_cracha,
      id_cliente: localStorage.getItem("id_cliente"),
    })
    .then((response) => {
      NotificaSuccess("Usuário criado com sucesso");
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
