import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateCentroCustoGestor } from "../../../Functions/CentroCustoGestor/CreateCentroCustoGestor";
import { FindCentroCustoGestor } from "../../../Functions/CentroCustoGestor/FindCentroCustoGestor";
import { FindUsuarios } from "../../../Functions/Usuarios/FindUsuarios";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  info: Array;
}

Modal.setAppElement("#root");

export function CentroCustoGestor({
  isOpen,
  handleChangeModalIsOpen,
  info,
}: Action) {
  const [listaGestor, setlistaGestor] = useState([]);
  const [listaUsuarios, setlistaUsuarios] = useState([]);
  const [reload, setreload] = useState(false);
  useEffect(() => {
    if (isOpen) {
      FindCentroCustoGestor(info.id, "S").then((res) => {
        setlistaGestor(res);
      });

      FindUsuarios("S").then((res) => {
        setlistaUsuarios(res);
      });
    }
  }, [isOpen, info, reload]);

  const handleCreateUsuarioGestor = (data) => {
    CreateCentroCustoGestor(info.id, data).then(() => {
      setreload(!reload);
    });
  };

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <h2>{info.nm_centro_custo}</h2>
        {listaGestor.map((info) => (
          <div>
            <p>Gestor Atual: {info.nm_usuario}</p>
          </div>
        ))}

        {listaGestor.length < 1 ? (
          <select onChange={(e) => handleCreateUsuarioGestor(e.target.value)}>
            <option value="DEFAULT" selected disabled>
              Usuários ativos.
            </option>
            {listaUsuarios.map((usuario) => (
              <option value={usuario.id}>
                {usuario.nm_centro_custo} - {usuario.nm_usuario}
              </option>
            ))}
          </select>
        ) : (
          ""
        )}
      </Container>
    </Modal>
  );
}
