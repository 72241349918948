import { NotificaError } from "../Toast/error";
import api from "../../services/api";

/* eslint eqeqeq: 0 */

export async function FindAutenticar(usuario, senha) {
  return await api
    .post("usuarios/login ", { usuario, senha })
    .then(response => {
      localStorage.setItem("token", "Bearer " + response.data.token);
      localStorage.setItem("id_cliente", response.data.user[0].id_cliente);
      localStorage.setItem("id_centro_custo", response.data.user[0].id_centro_custo);
      localStorage.setItem("id_tipo_acesso", response.data.user[0].id_tipo_acesso);

      localStorage.setItem("nm_usuario", response.data.user[0].nm_usuario);
      localStorage.setItem("usuario", response.data.user[0].usuario);
      localStorage.setItem("email", response.data.user[0].email);
      localStorage.setItem("phone", response.data.user[0].phone);
      localStorage.setItem("tp_acesso", response.data.user[0].tp_acesso);
      localStorage.setItem("nm_centro_custo", response.data.user[0].nm_centro_custo);
      localStorage.setItem("id_usuario", response.data.user[0].id);
      window.location.href = "/dashboard";

      return response.data;
    })
    .catch(err => {
      NotificaError("Usuário ou senha inválidos");
      return err;
    });
}
