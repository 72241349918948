import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 80vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  place-content: center;
  align-items: center;
  form {
    width: 30% !important;
    input {
      margin-bottom: 8px;
      height: 3rem;
      width: 100% !important;
    }
  }

  img {
    margin-bottom: 1rem;
  }

  h1 {
    margin: 1rem;
  }

  @media (max-width: 600px) {
    form {
      width: 80% !important;
    }
  }
`;
