import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function CreateSetor(nm_setor) {
  return await api
    .post("setores/create", {
      id_cliente: localStorage.getItem("id_cliente"),
      nm_setor
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("erro ao criar setor");
      return err;
    });
}
