import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function DeletePergunta(id) {
  return await api
    .post("perguntas/delete/id", {
      id_cliente: localStorage.getItem("id_cliente"),
      id
    })
    .then((response) => {
      NotificaSuccess('Deletado com sucesso...');
      return response.data;
    })
    .catch((err) => {
      NotificaError("erro perguntas/delete/id");
      return err;
    });
}
