import React, { useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateSetor } from "../../../Functions/Setores/CreateSetor";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  setores: Array;
}

Modal.setAppElement("#root");

function SetorCreate({isOpen,handleChangeModalIsOpen}: Action) {

  const [nm_setor, setnm_setor] = useState("");

  async function handleCreateSetor(e) {
    e.preventDefault();
    CreateSetor(nm_setor).then(() => {
      handleChangeModalIsOpen();
    })
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreateSetor} encType="multipart/form-data">
          <InputDefault place="Descrição do setor" handleSetInfo={setnm_setor} valor={nm_setor} />
          <button type="submit"> Criar setor</button>
        </form>
      </Container>
    </Modal>
  );
}

export default React.memo(SetorCreate);
