import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function CreateAvaliacao(desc_avaliacao, data_competencia) {
  return await api
    .post("avaliacoes/create ", {
      id_cliente: localStorage.getItem("id_cliente"),
      desc_avaliacao,
      data_competencia,
      usuario_criacao: localStorage.getItem("usuario"),
    })
    .then((response) => {
      NotificaSuccess(`${desc_avaliacao} criada com sucesso...`);
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro ao criar avaliação");
      return err;
    });
}
