import React, { useEffect, useState } from "react";

import { AvaliacaoSelecionada } from "../../Components/Avaliacoes/AvaliacaoSelecionada";
import AvaliacoesItem from "../../Components/Avaliacoes/AvaliacoesItem";
import { FindAvaliacoesPorAvaliador } from "../../Functions/Avaliacoes/FindAvaliacoesPorAvaliador";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";

export default function Dashboard() {
  const nm_usuario = localStorage.getItem("usuario");
  const [avaliacoesPendente, setavaliacoesPendente] = useState([]);
  const [avaliacaoSelecionada, setavaliacaoSelecionada] = useState([]);
  const [isOpenAvaliacaoSelecionada, setisOpenAvaliacaoSelecionada] =
    useState(false);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpenAvaliacaoSelecionada) {
      setLoading(true);
      FindAvaliacoesPorAvaliador().then((res) => {
        setavaliacoesPendente(res);
        setLoading(false);
      });
    }
  }, [nm_usuario, isOpenAvaliacaoSelecionada]);

  const handleOpenAvaliacaoSelecionada = (data) => {
    if (data) {
      setavaliacaoSelecionada(data);
    }
    setisOpenAvaliacaoSelecionada(!isOpenAvaliacaoSelecionada);
  };

  return (
    <div>
      <Loader display={Loading} />
      <Header nm_usuario={nm_usuario} />
      <AvaliacoesItem
        infos={avaliacoesPendente}
        handleOpenAvaliacaoSelecionada={handleOpenAvaliacaoSelecionada}
      />

      <AvaliacaoSelecionada
        isOpen={isOpenAvaliacaoSelecionada}
        handleChangeModalIsOpen={() =>
          setisOpenAvaliacaoSelecionada(!isOpenAvaliacaoSelecionada)
        }
        avaliacaoSelecionada={avaliacaoSelecionada}
      />
    </div>
  );
}
