import { Container, Table } from "./styles";

import React from "react";

interface Action {
  infos: Array;
  handleDeleteItem: () => void;
}

export const AvaliacaoMainResumoPerguntas = ({infos,handleDeleteItem}: Action) => (
  <Container>
    <Table>
      <thead>
        <th>Qtde</th>
        <th>Competência</th>
      </thead>
      {infos.map((info, index) => (
        <tr>
          <td>{info[1]}</td>
          <td>{info[0]}</td>
      </tr>
      ))}
    </Table>
  </Container>
);
