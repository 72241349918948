import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindTiposAcesso() {
  return await api
    .post("tipos/acesso/find")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
