import { FiArchive, FiFolder, FiSunrise, FiUser } from "react-icons/fi";

import ListStyle from "../../../styles/ListStyle";
import React from "react";

interface Action {
  infos: Array;
  handleOpenAvaliacaoSelecionada: () => void;
}

function AvaliacoesItem({ infos,handleOpenAvaliacaoSelecionada }: Action) {
  return (
    <ListStyle>
      {infos.map((info) => (
        <li onClick={() => handleOpenAvaliacaoSelecionada(info)}>
          <p><FiSunrise size="16"/> {info.desc_avaliacao}</p>
          <p><FiArchive size="16"/> {info.desc_avaliacao_tipo}</p>
          <p><FiUser size="16"/>{info.nm_usuario_alvo}</p>
          <p><FiFolder size="16"/>{info.nm_centro_custo}</p>
      </li>
      ))}
    </ListStyle>
  );
}

export default React.memo(AvaliacoesItem);
