import api from "../../services/api";

export async function CreateItAvaliacoesPerguntas(id_it_avaliacao,id_avaliacao_pergunta) {
  return await api
    .post("avaliacoes/it/create/perguntas", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_it_avaliacao,
      id_avaliacao_pergunta
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}
