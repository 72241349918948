import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindPerguntasCompetencia(id_competencia) {
  return await api
    .post("perguntas/find/competencia", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_competencia,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Usuário ou senha inválidos");
      return err;
    });
}
