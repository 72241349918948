import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import {CompetenciaCreate} from "../Create";
import { Container } from "../../styles";
import { FindCompetencias } from "../../../Functions/Competencias/FindCompetencias";
import HeaderLinksModal from "../../../styles/HeaderLinksModal";
import { LinkDefault } from "../../Others/LinkDefault";
import Loader from "../../Loader";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";
import {UsuariosUpdate} from "../Update";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function CompetenciasLista({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [competencias, setcompetencias] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [id_competencia, setid_competencia] = useState(false);
  const [isOpenEdit, setisOpenEdit] = useState(false);
  const [isOpenCreate, setisOpenCreate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindCompetencias().then((res) => {
        setcompetencias(res);
        setLoading(false);
      });
    } else {
      setcompetencias([]);
    }
  }, [isOpen, id_cliente, isOpenCreate,isOpenEdit]);

  const handleOpenEdit= (data) => {
    setid_competencia(data);
    setisOpenEdit(true);
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <HeaderLinksModal>
        <LinkDefault
          titleLink="Adicionar Competência"
          tipoBotao="link-secondary"
          FunctionClick={() => setisOpenCreate(true)}
        />
      </HeaderLinksModal>
      <Container>
        <select onChange={(e) => handleOpenEdit(e.target.value)}>
          <option value="DEFAULT" selected disabled>
            Competências Existentes
          </option>
          {competencias.map((competencia) => (
            <option value={competencia.id}>{competencia.desc_competencia_pergunta}</option>
          ))}
        </select>
      </Container>
      <CompetenciaCreate
        isOpen={isOpenCreate}
        handleChangeModalIsOpen={() => setisOpenCreate(false)}
      />
      <UsuariosUpdate
        isOpen={isOpenEdit}
        handleChangeModalIsOpen={() => setisOpenEdit(false)}
        id_competencia={id_competencia}
      />
    </Modal>
  );
}
