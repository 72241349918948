import "./styles.css";

import Lottie from "react-lottie";
import React from "react";
import animationData from "../../assets/lottie/profile.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function Loader({ display }) {
  return (
    <div>
      {display && (
        <div className="loader">
          <Lottie options={defaultOptions} height={600} width={600} />;{display}
        </div>
      )}
    </div>
  );
}

export default Loader;
