import React, { useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateAvaliacao } from "../../../Functions/Avaliacoes/CreateAvaliacao";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function AvaliacaoCreate({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {

  const [desc_avaliacao, setdesc_avaliacao] = useState("");
  const [data_competencia, setdata_competencia] = useState("");

  async function handleCreateAvaliacao(e) {
    e.preventDefault();
    CreateAvaliacao(desc_avaliacao, data_competencia).then(() => {
      handleChangeModalIsOpen();
    })
  };

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreateAvaliacao} encType="multipart/form-data">

          <InputDefault place="Descrição da Avaliação" handleSetInfo={setdesc_avaliacao} />
          <small>Competência:</small>
          <input type="date" onChange={(e) => setdata_competencia(e.target.value)}/>

          <button type="submit">Criar Avaliação</button>
        </form>
      </Container>
    </Modal>
  );
}

