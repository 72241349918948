import React, { useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateRespostas } from "../../../Functions/Respostas/CreateRespostas";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

function RespostasCreate({ isOpen, handleChangeModalIsOpen }: Action) {
  const [vl_resposta, setvl_resposta] = useState([]);
  const [desc_resposta, setdesc_resposta] = useState([]);

  async function handleCreateResposta(e) {
    e.preventDefault();
    CreateRespostas(desc_resposta, vl_resposta).then(() => {
      handleChangeModalIsOpen();
    });
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreateResposta} encType="multipart/form-data">
          <InputDefault
            place="Descrição da resposta"
            handleSetInfo={setdesc_resposta}
          />
          <input
            type="number"
            onChange={(e) => setvl_resposta(e.target.value)}
          />
          <button type="submit">Salvar</button>
        </form>
      </Container>
    </Modal>
  );
}

export default React.memo(RespostasCreate);
