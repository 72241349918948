import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function CreateCentroCusto(id_setor, nm_centro_custo) {
  return await api
    .post("centro/custo/create", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_setor,
      nm_centro_custo,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
