import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { FindUsuariosByID } from "../../../Functions/Usuarios/FindUsuariosByID";
import { InputDefault } from "../../Others/Input";
import Loader from "../../Loader";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";
import { UpdateUsuario } from "../../../Functions/Usuarios/UpdateUsuario";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
  id_usuario: String;
}

Modal.setAppElement("#root");

export function UsuariosUpdate({
  isOpen,
  handleChangeModalIsOpen,
  id_usuario
}: Action) {

  const [Loading, setLoading] = useState(false);
  const [nm_usuario, setnm_usuario] = useState("");
  const [usuario, setusuario] = useState("");
  const [sn_ativo, setsn_ativo] = useState("");
  const [phone, setphone] = useState("");
  const [nr_cracha, setnr_cracha] = useState("");

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      FindUsuariosByID(id_usuario).then((res) => {
        if(res.length >= 1) {
          setusuario(res[0].usuario);
          setnr_cracha(res[0].nr_cracha);
          setphone(res[0].phone);
          setnm_usuario(res[0].nm_usuario);
        }
        setLoading(false);
      })
    }
  }, [isOpen,id_usuario]);

  async function handleUpdateUsuario(e) {
    e.preventDefault();
    UpdateUsuario(id_usuario,sn_ativo, nm_usuario, phone, nr_cracha).then((res) => {
      handleChangeModalIsOpen();
    })
  };


  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <Loader display={Loading} />
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleUpdateUsuario} encType="multipart/form-data">
          <InputDefault
            place="Nome do usuário"
            handleSetInfo={setnm_usuario}
            valor={nm_usuario}
          />
          <select onChange={(e) => setsn_ativo(e.target.value)}>
            <option value="DEFAULT" selected disabled>Selecione um status</option>
            <option value="S">Ativo</option>
            <option value="N">Inativo</option>
          </select>
          <InputDefault place="Celular" handleSetInfo={setphone} valor={phone} />
          <InputDefault place="Crachá" handleSetInfo={setnr_cracha} valor={nr_cracha} />
          <button type="submit">Atualizar Usuário</button>
          <small>Código do usuário: {id_usuario}</small>
          <br />
          <small>{usuario}</small>
        </form>
      </Container>
    </Modal>
  );
}
