import { NotificaError } from "../Toast/error";
import { NotificaSuccess } from "../Toast/success";
import api from "../../services/api";

export async function CreateCentroCustoGestor(id_centro_custo, id_usuario) {
  return await api
    .post("centro/custo/gestor/create", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_centro_custo,
      id_usuario,
    })
    .then((response) => {
      NotificaSuccess("Gestor criado com sucesso");
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
