export const StyleModal = {
  overlay: {},
  content: {
    background: "#f0f2f5",
    borderRadius: 8,
    position: "fixed",
    left: "5%",
    border: 2,
    width: "90%",
    height: "90%",
    alignContent: "center"
  }
};

export const ModalFull = {
  overlay: {},
  content: {
    background: "#f0f2f5",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  }
};

export const ModalSmall = {
  overlay: {
    display: "flex",
  },
  content: {
    background: "#f0f2f5",

    position: "fixed",
    top: "20%",
    left: "30%",
    border: 2,
    width: "40%",
    height: "40%",
    alignContent: "center"
  }
};


export const ModalSmallRed = {
  overlay: {
    display: "flex",
  },
  content: {
    background: "#e52e4d",
    color: "white",
    position: "fixed",
    top: "20%",
    left: "30%",
    border: 2,
    width: "40%",
    height: "40%",
    alignContent: "center"
  }
};
