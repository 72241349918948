import { CloseModal } from "../../../Others/CloseModal";
import { FiAlertTriangle } from "react-icons/fi";
import Modal from "react-modal";
import { ModalSmallRed } from "../../../../styles/modal.css";
import React from "react";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  setores: Array;
}

Modal.setAppElement("#root");

export function AvaliacoesPendentes({
  isOpen,
  handleChangeModalIsOpen,
  infos,
}: Action) {

  console.log(infos)

  return (
    <Modal transparent isOpen={isOpen} style={ModalSmallRed} id="modalConfig" onRequestClose={handleChangeModalIsOpen}>
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <h2><FiAlertTriangle color="white" size="32" /> Atenção! </h2>
      < br/>
      <p>As seguintes avaliações estão pendentes desse colaborador</p>
      < br/>
      {infos.map((avaliacao) => (
        <li><b>Avaliação:</b> {avaliacao.desc_avaliacao_tipo} <b>Colaborador:</b> {avaliacao.nm_usuario_alvo}</li>
      ))}
    </Modal>
  );
}
