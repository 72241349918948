import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindPerguntas() {
  return await api
    .post("perguntas/find", {
      id_cliente: localStorage.getItem("id_cliente"),
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
