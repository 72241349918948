import styled from "styled-components";

export const Container = styled.div`
margin-top: 1rem;
`

export const Form = styled.form`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;

  select {
    max-width: 40%;
  }
`;
