import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function UpdateUsuario(id,sn_ativo, nm_usuario, phone, nr_cracha) {
  const id_cliente = localStorage.getItem("id_cliente");
  return await api
    .post("usuarios/update/id", { id_cliente, id,sn_ativo, nm_usuario, phone, nr_cracha })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro usuarios");
      return err;
    });
}
