import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindItAvaliacoesPerguntas(id_it_avaliacao) {
  return await api
    .post("avaliacoes/it/find/perguntas ", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_it_avaliacao
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
