import React, { useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateCompetencia } from "../../../Functions/Competencias/CreateCompetencia";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

export function CompetenciaCreate({
  isOpen,
  handleChangeModalIsOpen,
}: Action) {
  const [desc_competencia_pergunta, setdesc_competencia_pergunta] = useState("");

  async function handleCreate(e) {
    e.preventDefault();
    CreateCompetencia(desc_competencia_pergunta).then(() => {
      handleChangeModalIsOpen();
    })
  };

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreate} encType="multipart/form-data">
          <InputDefault place="Descrição da competência" handleSetInfo={setdesc_competencia_pergunta} />
          <button type="submit">Salvar</button>
        </form>
      </Container>
    </Modal>
  );
}
