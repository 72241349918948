import { CloseModal } from "../../../Others/CloseModal";
import { Container } from "./styles";
import Modal from "react-modal";
import { ModalSmall } from "../../../../styles/modal.css";
import React from "react";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  infos: Array;
  desc_pergunta: String;
}

Modal.setAppElement("#root");

export function ViewRespostasAnteriores({
  isOpen,
  handleChangeModalIsOpen,
  infos,
  desc_pergunta
}: Action) {
  return (
    <Modal transparent isOpen={isOpen} style={ModalSmall} id="modalConfig"
      onRequestClose={handleChangeModalIsOpen}
    >
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <h2>{desc_pergunta}</h2>

      {infos.map((resposta) => (
        <Container>
          <p>{resposta.desc_avaliacao_tipo} NOTA: {resposta.vl_resposta}</p>
        </Container>
      ))}

    </Modal>
  );
}
