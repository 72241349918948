import "./styles.css";

import { Container } from "./styles";
import { FiX } from "react-icons/fi";
import React from "react";

interface Action {
  handleCloseModal: () => void;
}

export const CloseModal = ({ handleCloseModal }: Action) => (
  <Container>
    <FiX
      onClick={() => handleCloseModal()}
      color="red"
      size="32"
      title="Fechar"
    />
  </Container>
);
