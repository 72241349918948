import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;
  text-align: left !important;
  table {
    margin-top: 1rem;
  }
`;
export const Table = styled.table`
  text-align: left;
  width: 100%;
  thead {
    border-radius: 4px;
    padding: 8px;
    text-align: left;
    background-color: var(--primary-color0) !important;
    color: var(--white);

    th {
      border-radius: 4px;
      padding: 8px;
    }
  }

  tr {
    cursor: pointer;
  }
  tr td {
    padding: 8px;
  }
`;
