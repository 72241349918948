import { NotificaError } from "../Toast/error";
import api from "../../services/api";
import { toast } from "react-toastify";

export async function UpdateValorResposta(id,vl_resposta,id_avaliacao) {
console.log(id_avaliacao)

  return await api
    .post("avaliacoes/it/update/resposta", {
      id_cliente: localStorage.getItem("id_cliente"),
      id,
      vl_resposta,
      id_avaliacao
    })
    .then((response) => {
      toast.info('Resposta salva com sucesso...');
      return response.data;
    })
    .catch((err) => {
      NotificaError("Avaliação fechada... não pode ser respondida!");
      return err;
    });
}
