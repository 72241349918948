import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function CreateRespostas(desc_resposta,vl_resposta) {
  return await api
    .post("respostas/create ", {
      id_cliente: localStorage.getItem("id_cliente"),
      desc_resposta,
	    vl_resposta
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro!");
      return err;
    });
}
