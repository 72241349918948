import { NotificaError } from "../Toast/error";
import api from "../../services/api";

export async function FindCentroCustoGestor(id_centro_custo, sn_ativo) {
  return await api
    .post("centro/custo/gestor/find", {
      id_cliente: localStorage.getItem("id_cliente"),
      id_centro_custo,
      sn_ativo,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      NotificaError("Erro");
      return err;
    });
}
