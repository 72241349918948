import React, { useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreatePerguntasCompetencia } from "../../../Functions/Perguntas/CreatePerguntasCompetencia";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_competencia: String;
}

Modal.setAppElement("#root");

export function PerguntasCreate({
  isOpen,
  handleChangeModalIsOpen,
  id_competencia,
}: Action) {
  const [desc_pergunta, setdesc_pergunta] = useState("");

  async function handleCreatePergunta(e) {
    e.preventDefault();
    CreatePerguntasCompetencia(id_competencia, desc_pergunta).then((res) => {
      handleChangeModalIsOpen();
    });
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreatePergunta} encType="multipart/form-data">
          <InputDefault place="Criar pergunta para a competência" handleSetInfo={setdesc_pergunta} />
          <button type="submit">Salvar</button>
        </form>
      </Container>
    </Modal>
  );
}
