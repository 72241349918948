import React, { useEffect, useState } from "react";

import { CloseModal } from "../../Others/CloseModal";
import { Container } from "../../styles";
import { CreateUsuario } from "../../../Functions/Usuarios/CreateUsuario";
import { FindCentroCustoAll } from "../../../Functions/CentroCusto/FindCentroCustoAll";
import { FindTiposAcesso } from "../../../Functions/Usuarios/FindTiposAcesso";
import { InputDefault } from "../../Others/Input";
import Modal from "react-modal";
import { StyleModal } from "../../../styles/modal.css";

interface Action {
  isOpen: boolean;
  handleChangeModalIsOpen: () => void;
  id_cliente: String;
}

Modal.setAppElement("#root");

function UsuariosCreate({
  isOpen,
  handleChangeModalIsOpen,
  id_cliente,
}: Action) {
  const [tiposAcesso, settiposAcesso] = useState([]);
  const [centro_custo, setcentro_custo] = useState([]);
  const [id_centro_custo, setid_centro_custo] = useState("");

  const [nm_usuario, setnm_usuario] = useState("");
  const [usuario, setusuario] = useState("");
  const [senha, setsenha] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [nr_cracha, setnr_cracha] = useState("");
  const [id_tipo_acesso, setid_tipo_acesso] = useState("");

  useEffect(() => {
    if (isOpen) {
      FindTiposAcesso().then((res) => {
        settiposAcesso(res);
      });

      FindCentroCustoAll().then((res) => {
        setcentro_custo(res);
      });
    }
  }, [isOpen]);

  async function handleCreateUsuario(e) {
    e.preventDefault();
    CreateUsuario(
      nm_usuario,
      id_centro_custo,
      id_tipo_acesso,
      usuario,
      senha,
      email,
      phone,
      nr_cracha
    ).then((res) => {
      handleChangeModalIsOpen();
    });
  }

  return (
    <Modal transparent isOpen={isOpen} style={StyleModal} id="modalConfig">
      <CloseModal handleCloseModal={handleChangeModalIsOpen} />
      <Container>
        <form onSubmit={handleCreateUsuario} encType="multipart/form-data">
          <select onChange={(e) => setid_tipo_acesso(e.target.value)}>
            <option value="DEFAULT" selected disabled>
              Tipo de acesso
            </option>
            {tiposAcesso.map((usuario) => (
              <option value={usuario.id}>{usuario.tp_acesso}</option>
            ))}
          </select>
          <select onChange={(e) => setid_centro_custo(e.target.value)}>
            <option value="DEFAULT" selected disabled>
              Selecione o centro de custo
            </option>
            {centro_custo.map((centro) => (
              <option value={centro.id}>{centro.nm_centro_custo}</option>
            ))}
          </select>
          <InputDefault place="Nome do usuário" handleSetInfo={setnm_usuario} />
          <InputDefault place="Usuário" handleSetInfo={setusuario} />
          <InputDefault place="Senha" handleSetInfo={setsenha} />
          <InputDefault place="Email" handleSetInfo={setemail} />
          <InputDefault place="Celular" handleSetInfo={setphone} />
          <InputDefault place="Crachá" handleSetInfo={setnr_cracha} />
          <button type="submit">Salvar</button>
        </form>
      </Container>
    </Modal>
  );
}

export default React.memo(UsuariosCreate);
